<template>
  <div
    :class="[$style.overlays, $style[testClass], { [$style.test]: testClass !== '' }]"
    @mouseenter="mouseOver"
    @mouseleave="mouseOut"
    v-if="gameState !== null && gameState !== 'null'"
  >
    <template v-if="isConnected && gameState && ['DRAFTING', 'STRATEGY_TIME'].includes(gameState)">
      <draft />
    </template>
    <template v-if="isConnected && gameState && ['PRE_GAME', 'IN_PROGRESS'].includes(gameState)">
      <top-bar />
      <bottom-hud />
      <roshan-status />
      <popups />
      <quick-stats />
      <shopkeeper />
      <indicators v-if="gameState === 'IN_PROGRESS'" />
      <!-- <hotkeys /> -->
    </template>
  </div>
</template>

<script>
import TopBar from './topbar.vue'
import BottomHud from './bottom-hud.vue'
import RoshanStatus from './roshan-status.vue'
// import Hotkeys from './hotkeys.vue'
import Popups from './popups.vue'
import Draft from './draft.vue'
import QuickStats from './quick-stats.vue'
import Indicators from './indicators.vue'
import Shopkeeper from './shopkeeper.vue'

export default {
  name: 'Overlays',
  components: {
    TopBar,
    BottomHud,
    RoshanStatus,
    // Hotkeys,
    Popups,
    Draft,
    QuickStats,
    Indicators,
    Shopkeeper
  },
  computed: {
    testClass() {
      if (
        ['DRAFTING', 'STRATEGY_TIME'].includes(this.gameState) &&
        this.$route.query.test !== undefined
      ) {
        return 'test_draft'
      }

      const selectedPlayer = this.$store.getters.selectedPlayer
      if (selectedPlayer && this.$route.query.test !== undefined) {
        if (selectedPlayer.hero.name === 'morphling') {
          return 'test_6'
        } else if (selectedPlayer.abilities) {
          return `test_${selectedPlayer.abilities.length}`
        }
      }
      return ''
    },
    gameState() {
      return this.$store.getters.gameState
    },
    channelId() {
      return this.$store.getters.channelId
    },
    isConnected() {
      return this.$store.getters.isConnected
    }
  },
  sse: {
    name: 'BaseData',
    endpoint: 'base-data',
    events: [
      'GameState',
      'SelectedPlayer',
      'Heroes',
      'Abilities',
      'Inventory',
      'Stats',
      'RoshanStatus',
      'Draft',
      'Neutrals',
      'Reset'
    ]
  },
  mounted() {
    this.recalculateBaseFontSize()
    window.addEventListener('resize', this.recalculateBaseFontSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.recalculateBaseFontSize)
  },
  methods: {
    mouseOver() {
      this.$store.commit('MouseOver')
    },
    mouseOut() {
      this.$store.commit('MouseOut')
    },
    recalculateBaseFontSize() {
      const width = document.documentElement.clientWidth || document.body.clientWidth
      const fontSize = width / 85
      document.documentElement.style.fontSize = `${fontSize}px`
    }
  }
}
</script>

<style lang="scss" module>
.overlays {
  width: 100vw;
  aspect-ratio: 16 / 9;
  position: absolute;
  z-index: 0;
  overflow: hidden;
  user-select: none;
}

.test {
  background-size: contain;

  &_4 {
    background-image: url('~@/assets/imgs/test/4.jpg');
  }
  &_5 {
    background-image: url('~@/assets/imgs/test/5.jpg');
  }
  &_6 {
    background-image: url('~@/assets/imgs/test/6.jpg');
  }
  &_draft {
    background-image: url('~@/assets/imgs/test/draft.jpg');
  }
}
</style>
