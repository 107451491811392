<template>
  <div>
    <div v-show="!isHeroSelected" id="hud-quick-stats" :class="$style.container">
      <transition name="fade-down2">
        <div v-if="quickStats !== null" :class="$style.title">{{ quickStats.label }}</div>
      </transition>
      <transition name="fade-down2">
        <div
          v-show="mouseOver && !hideControls && !isShopOpen"
          :class="[
            $style.controls,
            {
              [$style.winProbability]: quickStats !== null && quickStats.label === 'Win Probability'
            }
          ]"
        >
          <div
            :class="[$style.button, { [$style.active]: active === 'stats' }]"
            @click="toggleActive('stats')"
          >
            <div>Stats</div>
            <font-awesome :class="$style.ficon" icon="fa-circle-chevron-down" />
          </div>
          <div
            :class="[$style.button, { [$style.active]: active === 'status' }]"
            @click="toggleActive('status')"
          >
            <div>Status</div>
            <font-awesome :class="$style.ficon" icon="fa-circle-chevron-down" />
          </div>
        </div>
      </transition>
      <transition name="fade-quick">
        <div
          :class="$style.close"
          @click="close"
          v-show="mouseOver && quickStats !== null && active === '' && !hideControls"
        >
          close <font-awesome :class="$style.ficon" icon="fa-close" />
        </div>
      </transition>
      <list :active="active" @selected="clear" />
    </div>
    <transition name="fade-down2">
      <component v-if="quickStats !== null" :is="quickStats.type" :data="quickStats" />
    </transition>
  </div>
</template>

<script>
import List from './components/quick-stats/list.vue'
import Stats from './components/quick-stats/stats.vue'
import Status from './components/quick-stats/status.vue'

export default {
  name: 'quick-stats',
  components: { List, Stats, Status },
  data() {
    return {
      active: ''
    }
  },
  computed: {
    hideControls() {
      return this.$store.getters.hideControls
    },
    mouseOver() {
      return this.$store.getters.mouseOver
    },
    quickStats() {
      return this.$store.getters.quickStats
    },
    isHeroSelected() {
      return this.$store.getters.selectedTopBarHero !== null
    },
    isShopOpen() {
      return this.$store.getters.shopkeeper !== null
    }
  },
  methods: {
    toggleActive(list) {
      if (this.active !== '' && this.active !== list) {
        this.active = ''
        setTimeout(() => {
          this.active = list
        }, 400)
      } else {
        this.active = list === this.active ? '' : list
      }
    },
    clear() {
      this.active = ''
    },
    close() {
      setTimeout(() => {
        this.$store.commit('CloseQuickStats')
      }, 500)
    }
  },
  watch: {
    mouseOver: {
      handler(flag, _) {
        if (!flag) {
          this.active = ''
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  left: 44.8vw;
  top: 3.3vw;
  width: 10.5vw;
  z-index: 60;
  transition: all 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4vw;
}

.title {
  color: $color_primary;
  width: 100%;
  text-shadow: none;
  text-transform: uppercase;
  font-size: 0.9vw;
  font-weight: bold;
  text-align: center;
  background: $color_black;
  padding: 0.4vw 0.6vw;
  border-radius: 0.4vw;
  border: 0.1vw solid $color_primary;
}

.controls {
  display: flex;
  justify-content: space-evenly;

  &.winProbability {
    margin-top: 1.2vw;
  }
}

.close {
  text-transform: uppercase;
  text-shadow: none;
  font-weight: bold;
  color: $color_white;
  font-size: 0.6vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2vw 0.6vw;
  border-radius: 0.2vw;
  background: rgba(rgb(143, 0, 0), 0.8);
  cursor: pointer;

  &:hover {
    transition: all ease 0.5s;
    background: rgba(rgb(143, 0, 0), 1);
  }

  .ficon {
    margin-left: 0.4vw;
    font-size: 0.8vw;
  }
}

.button {
  flex: 1;
  position: relative;
  color: $color_primary;
  font-size: 0.7vw;
  font-weight: bold;
  text-transform: uppercase;
  background: rgba(#162024, 0.95);
  padding: 0.4vw 0.6vw;
  border: 0.1vw solid $color_primary;
  transition: all 0.5s ease;
  text-shadow: none;
  cursor: pointer;
  border-radius: 0.4vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ficon {
    margin-left: 0.2vw;
    transition: transform ease 0.5s;
  }

  &:hover {
    color: $color_white;
    background: rgba($color_primary, 0.6);
  }

  &.active {
    color: $color_white;
    background: rgba($color_primary, 0.95);
    border: 0.1vw solid rgba($color_black, 0.5);
    text-shadow: 0 0 0.1vw rgba($color_black, 0.5);

    .ficon {
      transform: rotate(180deg);
    }
  }

  &:nth-child(1) {
    border-radius: 0.4vw 0 0 0.4vw;
  }

  &:nth-child(2) {
    border-radius: 0 0.4vw 0.4vw 0;
  }
}
</style>
