<template>
  <transition name="fade-left">
    <div v-if="show" :class="$style.container">
      <div v-for="team in teams" :class="$style.team" :key="`team-${team}`">
        <div :class="[$style.header, $style[team]]">
          <div :class="$style.teamName">{{ getTeamName(team) }}</div>
          <div :class="[$style.col, $style['col-aghs']]">Aghs</div>
          <div :class="[$style.col, $style['col-main']]">Inventory</div>
          <div :class="[$style.col, $style['col-neutral']]">Neutral</div>
          <div :class="[$style.col, $style['col-backpack']]">Backpack</div>
        </div>
        <div :class="$style.rows">
          <div
            v-for="(player, index) in getPlayers(team)"
            :key="`${team}-${index}`"
            :class="[$style.row, $style[`slot_${team === 'radiant' ? index : index + 5}`]]"
          >
            <div :class="$style.heroInfo">
              <img :class="$style.heroImage" :src="getHeroDetails(player.hero.name, 'image')" />
              <div :class="$style.info">
                <div :class="$style.player">{{ player.stats.name }}</div>
                <div :class="$style.hero">{{ getHeroDetails(player.hero.name, 'name') }}</div>
              </div>
            </div>
            <div :class="[$style.col, $style['col-aghs']]">
              <hero-aghanims :hero="player.hero" size="small" position="right" />
            </div>
            <div :class="[$style.col, $style['col-main']]">
              <div :class="$style.item" v-for="pIndex in 6" :key="`player-main-${index}-${pIndex}`">
                <div :class="$style.imgContainer">
                  <img
                    :class="$style.img"
                    :src="getItemDetails(player.inventory.main[pIndex - 1].name, 'image')"
                  />
                </div>
                <item-tooltip
                  :item="player.inventory.main[pIndex - 1].name"
                  :level="player.inventory.main[pIndex - 1].level"
                  position="right"
                  :delay="300"
                />
              </div>
            </div>
            <div :class="[$style.col, $style['col-neutral']]">
              <div :class="$style.item">
                <div :class="$style.imgContainer">
                  <img
                    :class="$style.img"
                    :src="getItemDetails(player.inventory.neutral.name, 'image')"
                  />
                </div>
                <item-tooltip
                  :item="player.inventory.neutral.name"
                  :enchantment="player.inventory.enchantment"
                  position="right"
                  :delay="300"
                />
              </div>
            </div>
            <div :class="[$style.col, $style['col-backpack']]">
              <div
                :class="$style.item"
                v-for="pIndex in 3"
                :key="`player-backpack-${index}-${pIndex}`"
              >
                <div :class="$style.imgContainer">
                  <img
                    :class="$style.img"
                    :src="getItemDetails(player.inventory.backpack[pIndex - 1].name, 'image')"
                  />
                </div>
                <item-tooltip
                  :item="player.inventory.backpack[pIndex - 1].name"
                  :level="player.inventory.backpack[pIndex - 1].level"
                  position="right"
                  :delay="300"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ItemTooltip from '../tooltips/item-tooltip.vue'
import HeroAghanims from '../hero-details/aghanims.vue'

export default {
  name: 'item-check',
  components: { HeroAghanims, ItemTooltip },
  data: () => {
    return {
      teams: ['radiant', 'dire']
    }
  },
  methods: {
    getTeamName(team) {
      return this.$store.getters.teams[team]
    },
    getPlayers(team) {
      if (team === 'radiant') {
        return this.$store.getters.radiantPlayers
      }
      return this.$store.getters.direPlayers
    }
  },
  computed: {
    show() {
      return this.$store.getters.popupItemCheck
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  top: 6.8vw;
  left: 0;
  background: rgba(#162024, 0.95);
  border-radius: 0 0.3vw 0.3vw 0;
  box-shadow: 0 0.05vw 0.2vw rgba($color_black, 0.5);
  border: 0.2vw solid rgba($color_black, 0.5);
}

.header {
  display: flex;
  align-items: center;
  padding: 0.4vw 0.8vw;
  color: rgba($color_white, 0.8);
  font-size: 0.7vw;
  font-weight: bold;
  font-family: 'Reaver';
  gap: 0.6vw;

  &.radiant .teamName {
    color: $color_white;
    text-shadow: 0.1vw 0.1vw 0.1vw $color_dark_green, 0.1vw 0 0.1vw $color_dark_green,
      0 0.1vw 0.1vw $color_dark_green, 0 0 0.1vw $color_dark_green;
  }

  &.dire .teamName {
    color: $color_white;
    text-shadow: 0.1vw 0.1vw 0.1vw $color_dark_red, 0.1vw 0 0.1vw $color_dark_red,
      0 0.1vw 0.1vw $color_dark_red, 0 0 0.1vw $color_dark_red;
  }
}

.teamName {
  width: 14vw;
  font-size: 1vw;
  text-transform: uppercase;
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 0.3vw;
}

.row {
  padding: 0.25vw 0.4vw;
  border-left: 0.2vw solid $color_white;
  display: flex;
  gap: 0.6vw;
  background: rgba($color_black, 0.4);

  &.slot_0 {
    border-color: $color_slot_0;
  }
  &.slot_1 {
    border-color: $color_slot_1;
  }
  &.slot_2 {
    border-color: $color_slot_2;
  }
  &.slot_3 {
    border-color: $color_slot_3;
  }
  &.slot_4 {
    border-color: $color_slot_4;
  }
  &.slot_5 {
    border-color: $color_slot_5;
  }
  &.slot_6 {
    border-color: $color_slot_6;
  }
  &.slot_7 {
    border-color: $color_slot_7;
  }
  &.slot_8 {
    border-color: $color_slot_8;
  }
  &.slot_9 {
    border-color: $color_slot_9;
  }
}

.heroInfo {
  display: flex;
  align-items: center;
  width: 14vw;
  margin-left: 0.1vw;
}

.heroImage {
  width: 3.4vw;
  border-radius: 0.2vw;
}

.info {
  display: flex;
  flex-direction: column;
  margin-left: 0.5vw;
}

.player {
  font-size: 1vw;
  color: $color_white;
  width: 10vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hero {
  font-size: 0.6vw;
  text-transform: capitalize;
  color: rgba($color_white, 0.6);
}

.col {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &-aghs {
    width: 2.5vw;
    justify-content: center;
  }

  &-main {
    display: flex;
    gap: 0.2vw;
    width: 17.2vw;
  }

  &-neutral {
    justify-content: center;
    width: 3.5vw;

    .imgContainer {
      height: 2vw;
      width: 2vw;
      border-radius: 30%;
    }
  }

  &-backpack {
    display: flex;
    gap: 0.2vw;
  }
}

.item {
  position: relative;
}

.imgContainer {
  position: relative;
  height: 2vw;
  width: 2.7vw;
  border-radius: 0.2vw;
  overflow: hidden;
  box-shadow: inset rgba(black, 0.8) 0 0 0.8vw;
  border: 0.1vw solid black;
}

.img {
  height: calc(100% + 0.1vw);
  position: absolute;
  top: -0.05vw;
  left: 50%;
  transform: translateX(-50%);
}
</style>
