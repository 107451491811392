var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item !== 'empty' && _vm.itemDetails)?_c('tooltip',{attrs:{"position":_vm.position,"disableMouse":_vm.disableMouse,"delay":_vm.delay,"hideControls":_vm.hideControls}},[_c('div',{class:_vm.$style.container},[_c('div',{class:[
        _vm.$style.header,
        _vm.itemDetails.is_neutral ? _vm.$style[("tier_" + (_vm.itemDetails.neutral_tier))] : '',
        _vm.isEnchantment ? _vm.$style[("tier_" + _vm.enchantmentTier)] : ''
      ]},[_c('img',{class:_vm.$style.image,attrs:{"src":_vm.itemImage}}),_c('div',{class:_vm.$style.labels},[_c('div',{class:[
            _vm.$style.name,
            ( _obj = {}, _obj[_vm.$style.long] = _vm.itemDetails.name.length >= 18, _obj[_vm.$style.xlong] = _vm.itemDetails.name.length >= 24, _obj )
          ]},[_vm._v(" "+_vm._s(_vm.itemDetails.name)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.itemDetails.cost > 0),expression:"itemDetails.cost > 0"}],class:_vm.$style.price},[_c('img',{class:_vm.$style.gold,attrs:{"src":require("@/assets/imgs/gold.png")}}),_c('div',{class:_vm.$style.cost},[_vm._v(_vm._s(_vm.itemDetails.cost))])]),(_vm.itemDetails.is_neutral)?_c('div',{class:_vm.$style.neutral},[_c('span',{class:_vm.$style[_vm.getItemTier(_vm.itemDetails.neutral_tier)]},[_vm._v(" Tier "+_vm._s(_vm.itemDetails.neutral_tier)+" ")]),_c('span',{class:_vm.$style.label},[_vm._v("Neutral Item")])]):_vm._e(),(_vm.isEnchantment)?_c('div',{class:_vm.$style.neutral},[_c('span',{class:_vm.$style[("tier_" + _vm.enchantmentTier)]},[_vm._v(" Tier "+_vm._s(_vm.enchantmentTier)+" ")]),_c('span',{class:_vm.$style.label},[_vm._v("Enchantment")])]):_vm._e()])]),_c('div',{class:_vm.$style.details},[_c('item-details',{attrs:{"itemDetails":_vm.itemDetails,"level":_vm.level,"isEnchantment":_vm.isEnchantment}})],1),(_vm.itemDetails.is_neutral && _vm.enchantment)?_c('div',{class:_vm.$style.enchantment},[_c('item-enchantment',{attrs:{"itemDetails":_vm.enchantmentDetails,"level":_vm.enchantment.level}})],1):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }