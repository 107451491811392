<template>
  <div :class="$style.container">
    <div
      v-if="item !== 'item'"
      :class="$style.image"
      :style="`background-image: url('${image}')`"
    ></div>
    <div
      v-if="enchantment.name !== 'empty'"
      :class="$style.enchantment"
      :style="`background-image: url('${enchantmentImage}')`"
    ></div>
    <item-tooltip
      v-if="item !== 'item'"
      :item="item"
      :enchantment="enchantment"
      position="bottom"
      :delay="300"
      :hideControls="true"
    />
  </div>
</template>

<script>
import ItemTooltip from '../../tooltips/item-tooltip.vue'

export default {
  name: 'bkb',
  components: { ItemTooltip },
  props: ['data'],
  methods: {},
  computed: {
    item() {
      return this.data.inventory.neutral.name
    },
    image() {
      return this.item === 'empty' ? 'empty' : this.getItemDetails(this.item, 'image')
    },
    enchantment() {
      return this.data.inventory.enchantment
    },
    enchantmentImage() {
      return this.enchantment.name === 'empty'
        ? 'empty'
        : this.getItemDetails(this.enchantment.name, 'image')
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
  width: 2.35vw;
  height: 2.35vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border ease 0.5s;
  background: rgba(#162024, 0.95);
  border-radius: 0.8vw;
  box-shadow: 0 0 0.2vw $color_black;
  border: 0.15vw solid $color_black;
  box-shadow: inset rgba(black, 0.8) 0 0 0.8vw;
}

.image {
  width: 100%;
  height: 100%;
  border-radius: 0.8vw;
  background-size: cover;
  background-position: center center;
}

.enchantment {
  position: absolute;
  bottom: -0.5vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 1.2vw;
  height: 1.2vw;
  border-radius: 0.8vw;
  background-size: cover;
  background-position: center center;
  border: 0.1vw solid $color_black;
}
</style>
