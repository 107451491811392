<template>
  <div id="hud-inventory" :class="$style.container">
    <div id="hud-inventory-main" :class="$style.main">
      <div
        v-for="(item, index) in inventory.main"
        :class="[
          $style.item,
          {
            [$style.empty]: item.name === 'empty',
            [$style.indicator]: item.name !== 'empty' && showIndicators
          }
        ]"
        :key="`main-item-${index}`"
      >
        <item-tooltip :item="item.name" :level="item.level" position="top" />
      </div>
    </div>
    <div id="hud-inventory-backpack" :class="$style.backpack">
      <div
        v-for="(item, index) in inventory.backpack"
        :class="[
          $style.item,
          {
            [$style.empty]: item.name === 'empty',
            [$style.indicator]: item.name !== 'empty' && showIndicators
          }
        ]"
        :key="`backpack-item-${index}`"
      >
        <item-tooltip :item="item.name" :level="item.level" position="top" />
      </div>
    </div>
    <div
      id="hud-inventory-neutral"
      :class="[
        $style.neutral,
        $style.item,
        {
          [$style.empty]: inventory.neutral.name === 'empty',
          [$style.indicator]: inventory.neutral.name !== 'empty' && showIndicators
        }
      ]"
    >
      <item-tooltip
        :item="inventory.neutral.name"
        :enchantment="inventory.enchantment"
        position="top"
      />
    </div>
  </div>
</template>

<script>
import ItemTooltip from '../tooltips/item-tooltip.vue'

export default {
  name: 'hud-inventory',
  components: { ItemTooltip },
  computed: {
    inventory() {
      return this.$store.getters.selectedPlayer.inventory
    },
    showIndicators() {
      return this.$store.getters.showIndicators
    }
  }
}
</script>

<style lang="scss" module>
@keyframes indicator {
  0% {
    box-shadow: inset 0 0 0.4vw rgba($color_primary, 0.9);
  }
  50% {
    box-shadow: inset 0 0 0.2vw rgba($color_primary, 0.9);
  }
}

.container {
  position: absolute;
  top: 0;
  left: 32.05vw;
  width: 14vw;
  height: 7.5vw;
  display: flex;
  flex-direction: column;
  padding: 0.45vw 3.25vw 0.25vw 0.35vw;
}

.main {
  height: 5vw;
  display: flex;
  flex-wrap: wrap;
  gap: 0.05vw 0.3vw;

  .item {
    height: 2.35vw;
    width: 3.15vw;
  }
}

.backpack {
  height: 1.8vw;
  display: flex;
  gap: 0.12vw;

  .item {
    height: 1.7vw;
    width: 3.25vw;
  }
}

.item {
  position: relative;
  transition: all ease-in-out 0.5s;

  &.indicator {
    animation: indicator linear 1s infinite alternate;
  }

  &:hover:not(.empty) {
    cursor: pointer;
    box-shadow: inset 0 0 0.4vw $color_primary;
  }
}

.neutral {
  position: absolute;
  right: 0.95vw;
  bottom: 3.1vw;
  height: 2.3vw;
  width: 2.3vw;
  border-radius: 50%;
}
</style>
