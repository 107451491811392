<template>
  <tooltip :position="position" :hideControls="hideControls">
    <div :class="$style.container">
      <div :class="$style.header">
        <div :class="$style.name">{{ abilityDetails.name }}</div>
        <div v-if="!preview" :class="$style.level">Level {{ ability.level }}</div>
      </div>
      <div :class="$style.content">
        <ability-details
          :ability="ability"
          :abilityDetails="abilityDetails"
          :aghanims="aghanims"
          :facet="facet"
        />
      </div>
    </div>
  </tooltip>
</template>

<script>
import Tooltip from './tooltip.vue'
import AbilityDetails from '@/views/overlays/components/ability-details/details.vue'

export default {
  name: 'ability-tooltip',
  components: { Tooltip, AbilityDetails },
  props: {
    ability: {
      type: Object,
      required: true
    },
    aghanims: {
      type: Object,
      required: true
    },
    facet: {
      type: String,
      default: '',
      required: false
    },
    position: {
      type: String,
      default: 'top'
    },
    preview: {
      type: Boolean,
      default: false
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    abilityDetails() {
      return this.getAbilityDetails(this.ability.name)
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 20.65vw;
  border-radius: 0.1vw;
  border: 0.15vw solid rgba($color_black, 0.5);
  background: #1b1c26 url('~@/assets/imgs/tooltip-bg.png') center top no-repeat;
  background-size: 100%;
  box-shadow: 0 0.05vw 0.1vw rgba($color_black, 0.5);
}

.header {
  height: 2.35vw;
  border-bottom: 0.1vw solid rgba($color_white, 0.1);
  display: flex;
  justify-content: space-between;
  padding: 0 0.8vw;
}

.name {
  font-family: 'Reaver';
  color: $color_white;
  font-size: 0.8vw;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 2.55vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 15vw;
}

.level {
  font-family: 'Reaver';
  color: $color_white;
  font-size: 0.7vw;
  line-height: 2.55vw;
}

.content {
  min-height: 4vw;
  padding: 0.4vw;
  position: relative;
}
</style>
