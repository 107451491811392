<template>
  <div v-if="itemDetails[type]" :class="$style.container">
    <div
      :class="$style[type]"
      v-for="(highlight, index) in itemDetails[type]"
      :key="`${itemDetails.data_name}-${type}-${index}`"
    >
      <div :class="$style.header">
        <div :class="$style.name">{{ type }}: {{ highlight.name }}</div>
        <div :class="$style.manaCd" v-if="withCooldown || withManaCost">
          <div :class="$style.manacost" v-if="withManaCost">
            <img :class="$style.icon" src="@/assets/imgs/mana.png" />
            <div>
              <span :class="$style.value">{{ itemDetails.manacost }}</span>
            </div>
          </div>
          <div :class="$style.healthcost" v-if="withHealthCost">
            <img :class="$style.icon" src="@/assets/imgs/health.png" />
            <div>
              <span :class="$style.value">{{ itemDetails.healthcost }}</span>
            </div>
          </div>
          <div :class="$style.cooldown" v-if="withCooldown">
            <img :class="$style.icon" src="@/assets/imgs/cd.png" />
            <div>
              <span :class="$style.value">{{ itemDetails.cooldown }}</span>
            </div>
          </div>
        </div>
      </div>
      <div :class="$style.details" v-html="applyLevel(highlight.desc, level)"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'item-highlight',
  props: {
    type: {
      type: String,
      required: true
    },
    itemDetails: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    withManaCost() {
      return (
        this.type === 'active' &&
        this.itemDetails &&
        this.itemDetails.manacost &&
        parseInt(this.itemDetails.manacost) > 0
      )
    },
    withHealthCost() {
      return (
        this.type === 'active' &&
        this.itemDetails &&
        this.itemDetails.healthcost &&
        parseInt(this.itemDetails.healthcost) > 0
      )
    },
    withCooldown() {
      return (
        this.type === 'active' &&
        this.itemDetails &&
        this.itemDetails.cooldown &&
        parseInt(this.itemDetails.cooldown) > 0
      )
    }
  },
  methods: {
    applyLevel(description, level) {
      if (level === 0) {
        return description
      }

      const sectionRegex = /(.*?):\s*(?:<strong>\d+<\/strong>(?:\s*\/\s*<strong>\d+<\/strong>)*)+/g
      const numberRegex = /<strong>(\d+)<\/strong>/g

      return description.replace(sectionRegex, (section, title) => {
        let count = 1
        return section.replace(numberRegex, (match, num) => {
          return count++ === level ? match : num
        })
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  font-size: 0.8rem;
}

.header {
  display: flex;
  justify-content: space-between;
}

.name {
  font-weight: bold;
  text-transform: capitalize;
  padding: 0.2rem 0.4rem;
}

.manaCd {
  display: flex;
  gap: 0.6rem;
  margin-right: 0.4rem;
}

.cooldown,
.manacost,
.healthcost {
  display: flex;
  align-items: center;
  gap: 0.05rem;
}

.value {
  font-weight: bold;
  color: white;
}

.icon {
  width: 1rem;
  margin-right: 0.2rem;
}

.details {
  padding: 0.2rem 0.4rem;
}

.use {
  .header {
    background: url('~@/assets/imgs/items/use-bg.png') no-repeat;
    background-size: cover;
    color: #95c07a;
  }

  .details {
    background: linear-gradient(90deg, #15221f 0%, #1a2926 100%);
    color: #7b8a72;
  }

  strong {
    color: #95c07a;
  }
}

.active,
.toggle {
  .header {
    background: url('~@/assets/imgs/items/active-bg.png') no-repeat;
    background-size: cover;
    color: #b0b0ff;
  }

  .details {
    background: linear-gradient(90deg, #171d2f 0%, #1b2437 100%);
    color: #7a80a7;
  }

  strong {
    color: #b0b0ff;
  }
}

.passive {
  .header {
    background: url('~@/assets/imgs/items/passive-bg.png') no-repeat;
    background-size: cover;
    color: #a5b4c7;
  }

  .details {
    background: linear-gradient(90deg, #1c262f 0%, #1b262f 100%);
    color: #778392;
  }

  strong {
    color: #a5b4c7;
  }
}
</style>
