<template>
  <div :class="$style.container">
    <item-behavior :itemDetails="itemDetails" />
    <item-attributes :itemDetails="itemDetails" :level="level" :isEnchantment="isEnchantment" />
    <item-highlight type="use" :itemDetails="itemDetails" :level="level" />
    <item-highlight type="active" :itemDetails="itemDetails" :level="level" />
    <item-highlight type="toggle" :itemDetails="itemDetails" :level="level" />
    <item-highlight type="passive" :itemDetails="itemDetails" :level="level" />
    <p v-if="description !== ''" :class="$style.description" v-html="description"></p>
    <p v-if="itemDetails.lore !== ''" :class="$style.lore" v-html="itemDetails.lore"></p>
  </div>
</template>

<script>
import ItemBehavior from './behavior.vue'
import ItemAttributes from './attributes.vue'
import ItemHighlight from './highlight.vue'

export default {
  name: 'item-details',
  components: { ItemBehavior, ItemAttributes, ItemHighlight },
  props: {
    itemDetails: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      required: false,
      default: 0
    },
    isEnchantment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    description() {
      return this.itemDetails.description ? this.itemDetails.description.join('<br />') : ''
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.4rem;
}

.description {
  background: linear-gradient(90deg, #1c262f 0%, #1b262f 100%);
  color: #7e8c9d;
  padding: 0.2rem 0.4rem;
  font-size: 0.8rem;
}

.lore {
  color: rgba($color_bluishgrey, 0.6);
  font-size: 0.6rem;
  font-style: italic;
  padding: 0.4rem 0.8rem;
  background: rgba($color_black, 0.4);
  border-radius: 0.2rem;
}
</style>
