var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container,attrs:{"id":"hud-inventory"}},[_c('div',{class:_vm.$style.main,attrs:{"id":"hud-inventory-main"}},_vm._l((_vm.inventory.main),function(item,index){
var _obj;
return _c('div',{key:("main-item-" + index),class:[
        _vm.$style.item,
        ( _obj = {}, _obj[_vm.$style.empty] = item.name === 'empty', _obj[_vm.$style.indicator] = item.name !== 'empty' && _vm.showIndicators, _obj )
      ]},[_c('item-tooltip',{attrs:{"item":item.name,"level":item.level,"position":"top"}})],1)}),0),_c('div',{class:_vm.$style.backpack,attrs:{"id":"hud-inventory-backpack"}},_vm._l((_vm.inventory.backpack),function(item,index){
      var _obj;
return _c('div',{key:("backpack-item-" + index),class:[
        _vm.$style.item,
        ( _obj = {}, _obj[_vm.$style.empty] = item.name === 'empty', _obj[_vm.$style.indicator] = item.name !== 'empty' && _vm.showIndicators, _obj )
      ]},[_c('item-tooltip',{attrs:{"item":item.name,"level":item.level,"position":"top"}})],1)}),0),_c('div',{class:[
      _vm.$style.neutral,
      _vm.$style.item,
      ( _obj = {}, _obj[_vm.$style.empty] = _vm.inventory.neutral.name === 'empty', _obj[_vm.$style.indicator] = _vm.inventory.neutral.name !== 'empty' && _vm.showIndicators, _obj )
    ],attrs:{"id":"hud-inventory-neutral"}},[_c('item-tooltip',{attrs:{"item":_vm.inventory.neutral.name,"enchantment":_vm.inventory.enchantment,"position":"top"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }