<template>
  <div>
    <transition name="fade-left">
      <div
        id="hud-popup-buttons"
        v-show="mouseOver || scoreboard || itemCheck || showHotkeys || settings || initialize"
        :class="$style.buttons"
      >
        <div
          :class="[$style.button, { [$style.active]: settings }]"
          @click="toggleSettings"
          title="Extension Settings"
        >
          <font-awesome icon="gear" />
        </div>
        <div
          :class="[$style.button, { [$style.active]: scoreboard }]"
          @click="toggleScoreboard"
          title="Toggle Scoreboard"
        >
          Scoreboard
          <hotkey v-if="showHotkeys" position="lower">`</hotkey>
        </div>
        <div
          :class="[$style.button, { [$style.active]: itemCheck }]"
          @click="toggleItemCheck"
          title="Toggle Items"
        >
          Items
          <hotkey v-if="showHotkeys" position="lower">Tab</hotkey>
        </div>
      </div>
    </transition>
    <ability-video />
    <scoreboard />
    <item-check />
    <language />
  </div>
</template>

<script>
import AbilityVideo from './components/popups/ability-video.vue'
import Scoreboard from './components/popups/score-board.vue'
import ItemCheck from './components/popups/item-check.vue'
import Language from './components/popups/language.vue'
import Hotkey from './components/popups/hotkey.vue'

export default {
  name: 'popups',
  components: { AbilityVideo, Scoreboard, ItemCheck, Language, Hotkey },
  data() {
    return {
      initialize: true
    }
  },
  computed: {
    scoreboard() {
      return this.$store.getters.popupScoreboard
    },
    itemCheck() {
      return this.$store.getters.popupItemCheck
    },
    settings() {
      return this.$store.getters.popupSettings
    },
    mouseOver() {
      return this.$store.getters.mouseOver
    },
    showHotkeys() {
      return this.$store.getters.showHotkeys
    }
  },
  methods: {
    toggleScoreboard() {
      this.$store.commit('ToggleScoreboard')
    },
    toggleItemCheck() {
      this.$store.commit('ToggleItemCheck')
    },
    toggleSettings() {
      this.$store.commit('ToggleSettings')
    }
  },
  mounted() {
    setTimeout(() => {
      this.initialize = false
    }, 5000)
  }
}
</script>

<style lang="scss" module>
.buttons {
  position: absolute;
  left: 0.4vw;
  top: 4.8vw;
  display: flex;
  cursor: pointer;
  gap: 0.2vw;
}

.button {
  position: relative;
  color: $color_primary;
  font-size: 0.7vw;
  font-weight: bold;
  text-transform: uppercase;
  background: rgba(#162024, 0.95);
  padding: 0.4vw 0.6vw;
  border-radius: 0.4vw;
  border: 0.1vw solid $color_primary;
  transition: all 0.5s ease;
  text-shadow: none;

  &:hover {
    color: $color_white;
    background: rgba($color_primary, 0.6);
  }

  &.active {
    color: $color_white;
    background: rgba($color_primary, 0.95);
    border: 0.1vw solid rgba($color_black, 0.5);
    text-shadow: 0 0 0.1vw rgba($color_black, 0.5);
  }
}
</style>
