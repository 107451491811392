// import { applyChange } from 'deep-diff'

// export default function updateData(base, updates) {
//   try {
//     if (Array.isArray(base)) {
//       const copy = [...base]
//       updates.forEach((update) => applyChange(copy, undefined, update))
//       return copy
//     } else if (typeof base === 'object') {
//       const copy = { ...base }
//       updates.forEach((update) => applyChange(copy, undefined, update))
//       return copy
//     } else {
//       const copy = updates[0].rhs
//       return copy
//     }
//   } catch (e) {
//     return updateDataOld(base, updates)
//   }
// }

function setNestedValue(obj, path, value) {
  let current = obj

  for (let i = 0; i < path.length - 1; i++) {
    current = current[path[i]]
  }

  current[path[path.length - 1]] = value
}

function setNestedArrayValue(obj, path, index, value) {
  let current = obj

  for (let i = 0; i < path.length - 1; i++) {
    current = current[path[i]]
  }

  current[path[path.length - 1]][index] = value
}

function deletePath(obj, path) {
  let current = obj

  for (let i = 0; i < path.length - 1; i++) {
    current = current[path[i]]
  }

  delete current[path[path.length - 1]]
}

function deletePathIndex(obj, path, index) {
  let current = obj

  for (let i = 0; i < path.length - 1; i++) {
    current = current[path[i]]
  }

  current[path[path.length - 1]].splice(index, 1)
}

export default function updateData(base, update) {
  let newData = Array.isArray(base) ? [...base] : { ...base }

  for (const change of update) {
    switch (change.kind) {
      case 'N':
      case 'E':
        if (change.path !== undefined) {
          setNestedValue(newData, change.path, change.rhs)
        } else {
          newData = change.rhs
        }
        break
      case 'D':
        if (change.path !== undefined) {
          deletePath(newData, change.path)
        } else {
          newData = change.rhs
        }
        break
      case 'A':
        if (change.item.kind !== 'D') {
          if (change.path !== undefined) {
            setNestedArrayValue(newData, change.path, change.index, change.item.rhs)
          } else {
            newData[change.index] = change.rhs
          }
        } else {
          if (change.path !== undefined) {
            deletePathIndex(newData, change.path, change.index)
          } else {
            newData.splice(change.index, 1)
          }
        }
        break
      default:
        console.log('Unhandled: ', change)
    }
  }

  return newData
}
