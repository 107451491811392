import Vue from 'vue'
import Vuex from 'vuex'
import updateData from './helper'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gameState: null,
    selectedPlayer: null,
    heroList: [],
    heroes: [],
    abilities: [],
    inventory: [],
    stats: [],
    roshanStatus: null,
    draft: null,
    neutrals: null,
    // Popups
    selectedRadiantDraft: null,
    selectedDireDraft: null,
    selectedTopBarHero: null,
    previewedAbility: null,
    previewTimeout: null,
    quickStats: null,
    popupScoreboard: false,
    popupItemCheck: false,
    popupSettings: false,
    shopkeeper: null,
    // Mobile
    selectedHeroTab: 0,
    selectedHeroAbility: 0,
    selectedHeroItem: null,
    // Twitch
    streamLatency: 0,
    channelId: null,
    // General
    mouseOver: false,
    showHotkeys: false,
    showIndicators: false,
    connected: false,
    hideControls: false
  },
  actions: {
    commitDelay({ commit, state }, command) {
      setTimeout(() => {
        commit(command.mutation, { data: command.data, isUpdate: command.update })
      }, state.streamLatency)
    }
  },
  mutations: {
    // Socket Data
    PGL_GameState(state, payload) {
      if (payload.isUpdate) {
        payload.data = updateData(state.gameState, payload.data)
      }

      if (
        payload.data === null ||
        (state.gameState &&
          !['PRE_GAME', 'IN_PROGRESS'].includes(state.gameState.state) &&
          ['PRE_GAME', 'IN_PROGRESS'].includes(payload.data.state)) ||
        (state.gameState &&
          !['DRAFTING', 'STRATEGY_TIME'].includes(state.gameState.state) &&
          ['DRAFTING', 'STRATEGY_TIME'].includes(payload.data.state))
      ) {
        state.selectedTopBarHero = null
        state.selectedRadiantDraft = null
        state.selectedDireDraft = null
        state.previewedAbility = null
        state.selectedHeroItem = null
        state.popupScoreboard = false
        state.popupItemCheck = false
        state.popupSettings = false
        state.shopkeeper = null
      }

      state.gameState = payload.data
    },
    PGL_SelectedPlayer(state, payload) {
      if (payload.isUpdate) {
        payload.data = updateData(state.selectedPlayer, payload.data)
      }

      state.selectedPlayer = payload.data
    },
    PGL_Heroes(state, payload) {
      if (payload.isUpdate) {
        payload.data = updateData(state.heroes, payload.data)
      }

      state.heroes = payload.data

      if (state.heroes) {
        state.heroList = state.heroes.map((hero, index) => {
          return {
            index,
            id: hero.id,
            name: hero.name
          }
        })
      }
    },
    PGL_Abilities(state, payload) {
      if (payload.isUpdate) {
        payload.data = updateData(state.abilities, payload.data)
      }

      state.abilities = payload.data
    },
    PGL_Inventory(state, payload) {
      if (payload.isUpdate) {
        payload.data = updateData(state.inventory, payload.data)
      }

      state.inventory = payload.data
    },
    PGL_Stats(state, payload) {
      if (payload.isUpdate) {
        payload.data = updateData(state.stats, payload.data)
      }

      state.stats = payload.data
    },
    PGL_RoshanStatus(state, payload) {
      if (payload.isUpdate) {
        payload.data = updateData(state.roshanStatus, payload.data)
      }

      state.roshanStatus = payload.data
    },
    PGL_Draft(state, payload) {
      if (payload.isUpdate) {
        payload.data = updateData(state.draft, payload.data)
      }

      state.draft = payload.data
    },
    PGL_Neutrals(state, payload) {
      if (payload.isUpdate) {
        payload.data = updateData(state.neutrals, payload.data)
      }

      state.neutrals = payload.data
    },
    // Popups
    ResetPopups(state) {
      state.selectedTopBarHero = null
      state.selectedRadiantDraft = null
      state.selectedDireDraft = null
      state.previewedAbility = null
      state.selectedHeroItem = null
      state.popupScoreboard = false
      state.popupItemCheck = false
      state.popupSettings = false
      state.shopkeeper = null
    },
    SetTopBarHero(state, heroId) {
      state.selectedTopBarHero = heroId
      state.selectedHeroAbility = 0
      state.selectedHeroItem = null
      state.popupScoreboard = false
      state.popupItemCheck = false
      state.popupSettings = false
      state.quickStats = null
      state.shopkeeper = null
      state.showIndicators = false
    },
    SetRadiantDraft(state, data) {
      state.selectedRadiantDraft = data
    },
    SetDireDraft(state, data) {
      state.selectedDireDraft = data
    },
    ToggleScoreboard(state) {
      state.popupScoreboard = !state.popupScoreboard
      state.popupItemCheck = false
      state.popupSettings = false
      state.selectedTopBarHero = null
      state.quickStats = null
      state.shopkeeper = null
      state.showIndicators = false
    },
    ToggleItemCheck(state) {
      state.popupItemCheck = !state.popupItemCheck
      state.popupSettings = false
      state.popupScoreboard = false
      state.selectedTopBarHero = null
      state.quickStats = null
      state.shopkeeper = null
      state.showIndicators = false
    },
    ToggleSettings(state) {
      state.popupSettings = !state.popupSettings
      state.popupItemCheck = false
      state.popupScoreboard = false
      state.selectedTopBarHero = null
      state.quickStats = null
      state.shopkeeper = null
      state.showIndicators = false
    },
    CloseQuickStats(state) {
      state.quickStats = null
    },
    SetQuickStats(state, data) {
      state.quickStats = data
      state.popupScoreboard = false
      state.popupItemCheck = false
      state.popupSettings = false
      state.selectedTopBarHero = null
      state.shopkeeper = null
      state.showIndicators = false
    },
    SetPreviewedAbility(state, ability) {
      if (state.previewedAbility === null || ability === null) {
        state.previewedAbility = ability
      } else if (state.previewedAbility.name !== ability.name) {
        state.previewedAbility = null
        // Give enough time to transition
        clearTimeout(state.previewTimeout)
        state.previewTimeout = setTimeout(() => {
          state.previewedAbility = ability
        }, 500)
      } else {
        // Close if the same ability
        state.previewedAbility = null
      }
      state.showIndicators = false
    },
    SetShopkeeper(state, item) {
      state.quickStats = null
      state.popupScoreboard = false
      state.popupItemCheck = false
      state.popupSettings = false
      state.selectedTopBarHero = null
      state.shopkeeper = item
      state.showIndicators = false
    },
    // App Mobile Data
    SetSelectedHeroTab(state, index) {
      state.selectedHeroTab = index
    },
    SetSelectedHeroAbility(state, index) {
      state.selectedHeroAbility = index
    },
    SetSelectedHeroItem(state, item) {
      state.selectedHeroItem = item
    },
    // Twitch Channel
    SetChannelId(state, channelId) {
      state.channelId = channelId
    },
    // Twitch Stream Latency
    SetStreamLatency(state, latency) {
      state.streamLatency = latency * 1000
    },
    // General
    MouseOver(state) {
      state.mouseOver = true
    },
    MouseOut(state) {
      state.mouseOver = false
    },
    ToggleHideControls(state, flag) {
      state.hideControls = flag
    },
    ToggleHotkeyPreview(state) {
      state.showHotkeys = !state.showHotkeys
    },
    HideIndicators(state) {
      state.showIndicators = false
    },
    ShowIndicators(state) {
      state.showIndicators = true
    },
    Disconnect(state) {
      state.connected = false
    },
    Connected(state) {
      state.connected = true
    }
  },
  getters: {
    isConnected(state) {
      return state.connected
    },
    matchId(state) {
      if (state.gameState) {
        return state.gameState.match_id
      }
      return null
    },
    gameState(state) {
      if (state.gameState) {
        return state.gameState.state
      }
      return null
    },
    winProbability(state) {
      return state.gameState.radiant_win_chance
    },
    leagueId(state) {
      if (state.gameState) {
        return state.gameState.league_id
      }
      return 0
    },
    teams(state) {
      if (state.gameState) {
        return {
          radiant: state.gameState.radiant_team,
          dire: state.gameState.dire_team
        }
      }
      return {
        radiant: 'The Radiant',
        dire: 'The Dire'
      }
    },
    clockTime(state) {
      if (state.gameState) {
        return state.gameState.clock_time
      }
      return null
    },
    scores(state) {
      if (state.gameState) {
        return state.gameState.scores
      }
      return {
        radiant: 0,
        dire: 0
      }
    },
    heroData(state) {
      if (
        state.heroes !== null &&
        state.abilities !== null &&
        state.inventory !== null &&
        state.stats !== null &&
        state.heroes !== null &&
        state.heroes.length > 0 &&
        state.abilities.length > 0 &&
        state.inventory.length > 0 &&
        state.stats.length > 0
      ) {
        return state.heroes.map((hero, index) => {
          return {
            hero: hero,
            abilities: state.abilities[index],
            inventory: state.inventory[index],
            stats: state.stats[index]
          }
        })
      }
      return null
    },
    selectedPlayer(state, getters) {
      if (getters.heroData && state.selectedPlayer !== null && state.selectedPlayer >= 0) {
        return getters.heroData[state.selectedPlayer]
      }
      return null
    },
    selectedRadiantDraft(state) {
      return state.selectedRadiantDraft
    },
    selectedDireDraft(state) {
      return state.selectedDireDraft
    },
    playerDataList(state, getters) {
      return getters.heroData
    },
    playerData(state, getters) {
      if (state.selectedTopBarHero !== null && state.selectedTopBarHero >= 0) {
        return getters.heroData[state.selectedTopBarHero]
      }
      return null
    },
    playerStats(state, getters) {
      if (getters.heroData) {
        return getters.heroData.map((data) => {
          return {
            ...data.stats
          }
        })
      }
      return null
    },
    roshanStatus(state) {
      return state.roshanStatus
    },
    heroList(state) {
      return state.heroList
    },
    radiantTeam(state) {
      if (state.heroList) {
        return state.heroList.filter((_, index) => {
          if (index < 5) {
            return true
          }
          return false
        })
      }
      return []
    },
    direTeam(state) {
      if (state.heroList) {
        return state.heroList.filter((_, index) => {
          if (index > 4) {
            return true
          }
          return false
        })
      }
      return []
    },
    radiantPlayers(state, getters) {
      if (getters.heroData) {
        return getters.heroData.filter((_, index) => {
          if (index < 5) {
            return true
          }
          return false
        })
      }
      return []
    },
    direPlayers(state, getters) {
      if (getters.heroData) {
        return getters.heroData.filter((_, index) => {
          if (index > 4) {
            return true
          }
          return false
        })
      }
      return []
    },
    selectedTopBarHero(state) {
      return state.selectedTopBarHero
    },
    previewedAbility(state) {
      return state.previewedAbility
    },
    selectedHeroTab(state) {
      return state.selectedHeroTab
    },
    selectedHeroItem(state) {
      return state.selectedHeroItem
    },
    selectedHeroAbility(state) {
      return state.selectedHeroAbility
    },
    draft(state) {
      return state.draft
    },
    quickStats(state) {
      return state.quickStats
    },
    radiantNeutrals(state) {
      if (state.neutrals !== null) {
        return state.neutrals.filter((_, index) => {
          if (index < 5) {
            return true
          }
          return false
        })
      }
      return []
    },
    direNeutrals(state) {
      if (state.neutrals !== null) {
        return state.neutrals.filter((_, index) => {
          if (index > 4) {
            return true
          }
          return false
        })
      }
      return []
    },
    // Popups
    popupScoreboard(state) {
      return state.popupScoreboard
    },
    popupItemCheck(state) {
      return state.popupItemCheck
    },
    popupSettings(state) {
      return state.popupSettings
    },
    shopkeeper(state) {
      return state.shopkeeper
    },
    // Twitch
    channelId(state) {
      return state.channelId
    },
    streamLatency(state) {
      return state.streamLatency
    },
    // General
    mouseOver(state) {
      return state.mouseOver
    },
    showHotkeys(state) {
      return state.showHotkeys
    },
    showIndicators(state) {
      return state.showIndicators
    },
    hideControls(state) {
      return state.hideControls
    }
  }
})
