<template>
  <div :class="$style.container">
    <div :class="$style.level">{{ level }}</div>
    <div :class="$style.branches">
      <div
        :class="[$style.talent, { [$style.active]: talent2.active }]"
        v-html="getTalentLabel(talent2)"
      ></div>
      <div
        :class="[$style.talent, { [$style.active]: talent1.active }]"
        v-html="getTalentLabel(talent1)"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'talent-level',
  props: ['level', 'talent1', 'talent2', 'facet'],
  methods: {
    getTalentLabel(talent) {
      if (typeof talent.label === 'object') {
        if (talent.label[this.facet] !== undefined) {
          return talent.label[this.facet]
        }
        return '<i>Failed to get facet talent!</i>'
      } else if (typeof talent.label === 'string') {
        return talent.label
      }
      return '<i>Failed to get facet talent!</i>'
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: relative;
}

.level {
  position: absolute;
  top: 0.05rem;
  left: 50%;
  transform: translateX(-50%);
  width: 2rem;
  height: 2rem;
  background: url('~@/assets/imgs/talents/level.png') center center no-repeat;
  background-size: contain;
  text-align: center;
  line-height: 2rem;
  color: $color_lightyellow;
  font-size: 0.9rem;
  font-weight: bold;
  text-shadow: 0.1rem 0.1rem 0.2rem $color_red, -0.1rem -0.1rem 0.2rem $color_red,
    -0.1rem 0.1rem 0.2rem $color_red, 0.1rem -0.1rem 0.2rem $color_red;
}

.branches {
  display: flex;
  justify-content: space-between;
  gap: 1.6rem;
}

.talent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.4rem;
  height: 2.1rem;
  font-size: 0.55rem;
  color: $color_fadewhite;
  text-align: center;

  &.active {
    color: $color_lightyellow;
    font-weight: bold;
    text-shadow: 0 0 0.2rem $color_red;
  }
}
</style>
