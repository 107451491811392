<template>
  <div :class="$style.container" v-if="playerData">
    <div :class="$style.portrait">
      <bg-loading />
      <video :class="$style.video" autoplay loop muted playsinline preload="auto">
        <source :src="heroVideo" type="video/mp4" />
      </video>
    </div>
    <hero-level :class="$style.level" :playerData="playerData" />
    <div :class="[$style.details, $style[team]]">
      <div :class="$style.name">
        <img :class="$style.attrib" :src="primaryAttribImage" />
        <span :class="$style.text">{{ heroDetails.name }}</span>
        <span v-if="morphed" :class="$style.morphed">Morphed</span>
      </div>
      <ul :class="$style.roles">
        <li
          :class="$style.role"
          v-for="(role, index) in heroDetails.roles"
          :key="`${heroDetails.id}-role-${index}`"
        >
          <div>{{ role }}</div>
          <ul :class="$style.role_level">
            <li
              :class="[$style.notch, { [$style.active]: lv <= heroDetails.role_levels[index] }]"
              v-for="lv in 3"
              :key="`${role}-lvl-${lv}`"
            ></li>
          </ul>
        </li>
      </ul>
      <div :class="$style.lower">
        <hero-talents :hero="playerData.hero" :withAttributes="true" />
        <hero-innate-facet :hero="playerData.hero" />
        <hero-abilities
          :heroName="playerData.hero.name"
          :abilities="playerData.abilities"
          :aghanims="aghanimsStatus"
          :facet="facet.key"
        />
        <hero-aghanims :hero="playerData.hero" />
      </div>
    </div>
  </div>
</template>

<script>
import HeroTalents from './talents.vue'
import HeroAbilities from './abilities.vue'
import HeroAghanims from './aghanims.vue'
import HeroLevel from './level.vue'
import HeroInnateFacet from './innate-facet.vue'
import BgLoading from '../common/BgLoading.vue'

export default {
  name: 'hero-details',
  components: {
    HeroTalents,
    HeroAbilities,
    HeroAghanims,
    HeroLevel,
    HeroInnateFacet,
    BgLoading
  },
  props: ['hero'],
  computed: {
    team() {
      return this.hero.index <= 4 ? 'radiant' : 'dire'
    },
    playerData() {
      return this.$store.getters.playerData
    },
    heroVideo() {
      return require(`@/assets/hero-videos/npc_dota_hero_${this.hero.name}.mp4`)
    },
    heroDetails() {
      return this.getHeroDetails(this.hero.name)
    },
    facet() {
      if (this.playerData && this.playerData.hero.facet) {
        return this.getFacetData(this.hero.name, this.playerData.hero.facet)
      }
      return { key: '' }
    },
    primaryAttribImage() {
      switch (this.heroDetails.primary_attr) {
        case 'agi':
          return require('@/assets/imgs/agility.png')
        case 'int':
          return require('@/assets/imgs/inteligence.png')
        case 'str':
          return require('@/assets/imgs/strength.png')
        case 'all':
          return require('@/assets/imgs/all.png')
        default:
          return ''
      }
    },
    aghanimsStatus() {
      return {
        scepter: this.playerData.hero.aghanims_scepter,
        shard: this.playerData.hero.aghanims_shard
      }
    },
    morphed() {
      return (
        this.playerData &&
        this.playerData.hero.name === 'morphling' &&
        !this.playerData.abilities[0].name.includes('morphling')
      )
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
}

.radiant {
  --team_color: #a8f4cc;
}

.dire {
  --team_color: #e26c20;
}

.portrait {
  height: 8.27vw;
  width: 6.2vw;
  overflow: hidden;
  position: relative;
}

.video {
  height: 8.27vw;
  width: 6.2vw;
  object-fit: fill;
  z-index: 10;
  position: absolute;
}

.level {
  position: absolute;
  z-index: 10;
  left: 0.1vw;
  bottom: 0.1vw;
  width: 1.8vw;
  height: 1.8vw;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 0.3vw 0.5vw;
}

.name {
  display: flex;
  align-items: center;
  padding-right: 2vw;
}

.attrib {
  width: 1.2vw;
  height: 1.2vw;
  margin-right: 0.4vw;
}

.text {
  color: var(--team_color);
  font-family: 'Reaver';
  font-weight: bold;
  font-size: 1vw;
  letter-spacing: 0.1vw;
  white-space: nowrap;
}

.morphed {
  color: $color_bluishgrey;
  font-family: 'Reaver';
  font-size: 0.6vw;
  font-style: italic;
  white-space: nowrap;
  margin-left: 0.4vw;
}

.roles {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 0.2vw 0.5vw;
  margin-top: 0.2vw;
  margin-left: 0.3vw;
  color: $color_white;
  font-size: 0.7vw;
  text-transform: uppercase;
  list-style: none;
}

.role {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.role_level {
  display: flex;
  gap: 0.2vw;
  list-style: none;
  margin-left: 0.1vw;
}

.notch {
  width: 0.4vw;
  height: 0.2vw;
  border-radius: 0.1vw;
  background-color: rgba($color_white, 0.2);

  &.active {
    background-color: #fff5bc;
  }
}

.lower {
  display: flex;
  gap: 0.4vw;
  align-items: center;
}
</style>
