<template>
  <tooltip
    :position="position"
    v-if="item !== 'empty' && itemDetails"
    :disableMouse="disableMouse"
    :delay="delay"
    :hideControls="hideControls"
  >
    <div :class="$style.container">
      <div
        :class="[
          $style.header,
          itemDetails.is_neutral ? $style[`tier_${itemDetails.neutral_tier}`] : '',
          isEnchantment ? $style[`tier_${enchantmentTier}`] : ''
        ]"
      >
        <img :class="$style.image" :src="itemImage" />
        <div :class="$style.labels">
          <div
            :class="[
              $style.name,
              {
                [$style.long]: itemDetails.name.length >= 18,
                [$style.xlong]: itemDetails.name.length >= 24
              }
            ]"
          >
            {{ itemDetails.name }}
          </div>
          <div :class="$style.price" v-show="itemDetails.cost > 0">
            <img :class="$style.gold" src="@/assets/imgs/gold.png" />
            <div :class="$style.cost">{{ itemDetails.cost }}</div>
          </div>
          <div :class="$style.neutral" v-if="itemDetails.is_neutral">
            <span :class="$style[getItemTier(itemDetails.neutral_tier)]">
              Tier {{ itemDetails.neutral_tier }}
            </span>
            <span :class="$style.label">Neutral Item</span>
          </div>
          <div :class="$style.neutral" v-if="isEnchantment">
            <span :class="$style[`tier_${enchantmentTier}`]"> Tier {{ enchantmentTier }} </span>
            <span :class="$style.label">Enchantment</span>
          </div>
        </div>
      </div>
      <div :class="$style.details">
        <item-details :itemDetails="itemDetails" :level="level" :isEnchantment="isEnchantment" />
      </div>
      <div :class="$style.enchantment" v-if="itemDetails.is_neutral && enchantment">
        <item-enchantment :itemDetails="enchantmentDetails" :level="enchantment.level" />
      </div>
    </div>
  </tooltip>
</template>

<script>
import Tooltip from './tooltip.vue'
import ItemDetails from '@/views/overlays/components/item-details/details.vue'
import ItemEnchantment from '@/views/overlays/components/item-details/enchantment.vue'

export default {
  name: 'item-tooltip',
  components: { Tooltip, ItemDetails, ItemEnchantment },
  props: {
    item: {
      type: String,
      required: true
    },
    enchantment: {
      type: Object,
      required: false
    },
    isEnchantment: {
      type: Boolean,
      required: false,
      default: false
    },
    enchantmentTier: {
      type: Number,
      required: false,
      default: 0
    },
    level: {
      type: Number,
      required: false,
      default: 0
    },
    position: {
      type: String,
      required: true
    },
    disableMouse: {
      type: Boolean,
      default: true
    },
    transition: {
      type: String,
      default: 'fade-down'
    },
    delay: {
      type: Number,
      default: 100
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    itemDetails() {
      return this.getItemDetails(this.item)
    },
    enchantmentDetails() {
      if (this.enchantment) {
        return this.getItemDetails(this.enchantment.name)
      }

      return {}
    },
    itemImage() {
      if (this.item === 'item_dagon') {
        if (this.level > 1) {
          return this.itemDetails.image.replace('dagon.png', `dagon_${this.level}.png`)
        }
        return this.itemDetails.image
      } else {
        return this.itemDetails.image
      }
    }
  },
  methods: {
    getItemTier(tier) {
      return `tier_${tier}`
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  width: 19.65vw;
  border-radius: 0.1vw;
  border: 0.15vw solid rgba($color_black, 0.5);
  background: #10171d url('~@/assets/imgs/items/tooltip-bg.png') center top no-repeat !important;
  background-size: 130% !important;
  box-shadow: 0 0.05vw 0.1vw rgba($color_black, 0.5);
}

.header {
  min-height: 3.7vw;
  display: flex;
  align-items: flex-start;
  padding: 0.6vw 0.4vw;
  gap: 0.6vw;

  &.tier_1 {
    background: linear-gradient(180deg, rgba(#bdbdbd, 0.6) 0%, transparent 100%);
  }

  &.tier_2 {
    background: linear-gradient(180deg, rgba(#74b365, 0.6) 0%, transparent 100%);
  }

  &.tier_3 {
    background: linear-gradient(180deg, rgba(#7f93fa, 0.6) 0%, transparent 100%);
  }

  &.tier_4 {
    background: linear-gradient(180deg, rgba(#c171e7, 0.6) 0%, transparent 100%);
  }

  &.tier_5 {
    background: linear-gradient(180deg, rgba(#eed28b, 0.6) 0%, transparent 100%);
  }
}

.image {
  height: 2.5vw;
  border-radius: 0.2vw;
  box-shadow: 0 0 0.3vw $color_black;
}

.labels {
  display: flex;
  flex-direction: column;
}

.name {
  flex: 1;
  font-family: 'Reaver';
  color: $color_white;
  font-size: 1vw;
  font-weight: bold;
  text-transform: uppercase;

  &.long {
    font-size: 0.8vw;
  }

  &.xlong {
    font-size: 0.75vw;
  }
}

.price {
  display: flex;
  align-items: center;
  gap: 0.4vw;
}

.neutral {
  display: flex;
  align-items: center;
  gap: 0.2vw;
  font-size: 0.8vw;
  font-weight: bold;

  .label {
    color: rgba(white, 0.5);
  }

  .tier_1 {
    color: $color_tier_1;
  }

  .tier_2 {
    color: $color_tier_2;
  }

  .tier_3 {
    color: $color_tier_3;
  }

  .tier_4 {
    color: $color_tier_4;
  }

  .tier_5 {
    color: $color_tier_5;
  }
}

.gold {
  width: 1.2vw;
  height: 1.2vw;
}

.cost {
  font-family: 'Reaver';
  color: gold;
  font-size: 0.7vw;
  font-weight: bold;
  text-transform: uppercase;
}
</style>
