<template>
  <div :class="$style.container" v-if="itemDetails.attributes">
    <ul :class="$style.attributes">
      <template v-for="(attribute, index) in itemDetails.attributes">
        <template v-if="!isEnchantment">
          <li :class="$style.attribute" :key="`${attribute.key}-attr-${index}`">
            <span :class="$style.header" v-html="attribute.header"></span>
            <span :class="$style.valueBlock">
              <span :class="$style.value" v-html="formatValue(attribute.value)"></span>
            </span>
            <span :class="$style.footer" v-html="attribute.footer"></span>
          </li>
        </template>
        <template v-if="isEnchantment">
          <li
            v-if="!isZero(attribute.value)"
            :class="$style.attribute"
            :key="`${attribute.key}-attr-${index}`"
          >
            <span :class="$style.header" v-html="attribute.header"></span>
            <span :class="$style.valueBlock">
              <span :class="$style.value" v-html="formatEnchantmentValue(attribute.value)"></span>
            </span>
            <span
              :class="[$style.footer, attribute.header === '-' ? $style.negative : '']"
              v-html="attribute.footer"
            ></span>
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'item-attributes',
  props: {
    itemDetails: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      required: false,
      default: 0
    },
    isEnchantment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    formatValue(value) {
      if (Array.isArray(value)) {
        if (this.level > 0) {
          const valHtml = []
          value.forEach((val, index) => {
            if (this.level === index + 1) {
              valHtml.push(`<span>${val}</span>`)
            } else {
              valHtml.push(val)
            }
          })

          return valHtml.join(' / ')
        }
        return `<span>${value.join('</span> / <span>')}</span>`
      }
      return `<span>${value}</span>`
    },
    isZero(value) {
      if (Array.isArray(value)) {
        return parseInt(value[this.level - 1]) === 0
      }
      return parseInt(value) === 0
    },
    formatEnchantmentValue(value) {
      if (Array.isArray(value)) {
        return `<span>${value[this.level - 1]}</span>`
      }
      return `<span>${value}</span>`
    }
  }
}
</script>

<style lang="scss" module>
.container {
  padding: 0.4rem 0.2rem 0 0.4rem;
}

.attributes {
  list-style: none;
}

.attribute {
  margin-bottom: 0.1rem;
  font-size: 0.8rem;
  letter-spacing: 0 !important;
}

.header {
  color: $color_lightbluishgrey;
  margin-right: 0.1rem;
}

.valueBlock {
  margin-right: 0.2rem;
}

.value {
  span {
    color: $color_white;
    font-weight: bold;
  }
}

.inactive {
  color: rgba($color_lightbluishgrey, 0.6);
}

.separator {
  color: $color_lightbluishgrey;
  padding: 0 0.1rem;
}

.footer {
  color: $color_lightbluishgrey;

  &.negative {
    color: $color_red;
  }
}
</style>
