<template>
  <div :class="$style.container">
    <div :class="$style.headers">
      <div>Artifact</div>
      <div>Enchantment</div>
    </div>
    <div
      :class="[$style.category, $style[category.type.replace(' ', '_')]]"
      v-for="(category, index) in categories"
      :key="`category-${index}`"
    >
      <div :class="$style.tier">
        <div :class="[$style.title, $style[category.type.replace(' ', '_')]]">
          {{ category.type }}
        </div>
        <div :class="$style.time">
          {{ category.time }}
        </div>
      </div>
      <div :class="[$style.grid, $style.artifacts]">
        <div
          :class="$style.artifact"
          v-for="(artifact, iIndex) in category.artifacts"
          :key="`${category.type}-artifact-${iIndex}`"
        >
          <div :class="$style.imgContainer">
            <img :class="$style.img" :src="getItemDetails(artifact, 'image')" />
          </div>
          <item-tooltip :item="artifact" position="left" :delay="300" />
        </div>
      </div>
      <div :class="[$style.grid, $style.enchantments]">
        <div
          :class="$style.enchantment"
          v-for="(enchantment, iIndex) in category.enchantments"
          :key="`${category.type}-enchantment-${iIndex}`"
        >
          <div :class="$style.imgContainer">
            <img :class="$style.img" :src="getItemDetails(enchantment.name, 'image')" />
          </div>
          <item-tooltip
            :item="enchantment.name"
            :level="enchantment.level"
            :isEnchantment="true"
            :enchantmentTier="index + 1"
            position="left"
            :delay="300"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTooltip from '../tooltips/item-tooltip.vue'

export default {
  name: 'neutral-list',
  components: { ItemTooltip },
  props: {
    categories: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.5vw;
  padding: 0.1vw;
}

.headers {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.1vw 0.4vw;
  padding-top: 0.2vw;
  color: rgba(white, 0.6);
  font-size: 0.9vw;
  font-weight: bold;
  text-transform: uppercase;
}

.category {
  display: flex;
  align-items: flex-start;
  padding: 0.4vw 0.4vw 0 0.4vw;
  border-radius: 0.2vw;
  gap: 0.2vw;

  &.tier_1 {
    background: linear-gradient(180deg, rgba(#bdbdbd, 0.2) 0%, transparent 100%);
  }

  &.tier_2 {
    background: linear-gradient(180deg, rgba(#74b365, 0.2) 0%, transparent 100%);
  }

  &.tier_3 {
    background: linear-gradient(180deg, rgba(#7f93fa, 0.2) 0%, transparent 100%);
  }

  &.tier_4 {
    background: linear-gradient(180deg, rgba(#c171e7, 0.2) 0%, transparent 100%);
  }

  &.tier_5 {
    background: linear-gradient(180deg, rgba(#eed28b, 0.2) 0%, transparent 100%);
  }
}

.tier {
  width: 16%;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2vw 0.2vw;

  &.artifacts {
    width: 50%;
  }

  &.enchantments {
    width: 33%;
  }
}

.title {
  color: rgba(white, 0.6);
  font-size: 0.9vw;
  font-weight: 900;
  text-transform: uppercase;

  &.tier_1 {
    color: $color_tier_1;
  }

  &.tier_2 {
    color: $color_tier_2;
  }

  &.tier_3 {
    color: $color_tier_3;
  }

  &.tier_4 {
    color: $color_tier_4;
  }

  &.tier_5 {
    color: $color_tier_5;
  }
}

.time {
  display: flex;
  align-items: center;
  color: rgba(white, 0.6);
  font-size: 0.8vw;

  .ficon {
    margin-right: 0.2vw;
    font-size: 0.7vw;
  }
}

.artifact,
.enchantment {
  .imgContainer {
    position: relative;
    height: 100%;
    height: 100%;
    border-radius: 0.2vw;
    overflow: hidden;
    box-shadow: inset rgba(black, 0.8) 0 0 0.8vw;
    border: 0.1vw solid black;
  }

  &:hover {
    box-shadow: inset $color_primary 0 0 0.4vw;
    border-radius: 0.2vw;
  }
}

.artifact {
  position: relative;
  height: 1.6vw;
  width: 2.2vw;
}

.enchantment {
  position: relative;
  height: 1.5vw;
  width: 1.5vw;
}

.img {
  height: calc(100% + 0.1vw);
  position: absolute;
  top: -0.05vw;
  left: 50%;
  transform: translateX(-50%);
}
</style>
