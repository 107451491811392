<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <div :class="$style.dash"></div>
      <div :class="$style.title">enchantment</div>
    </div>
    <div :class="$style.item">
      <div :class="$style.border">
        <img :class="$style.image" :src="itemDetails.image" />
      </div>
      <div :class="$style.name">{{ itemDetails.name }}</div>
    </div>
    <div :class="$style.stats" v-if="itemDetails.attributes">
      <ul :class="$style.attributes">
        <template v-for="(attribute, index) in itemDetails.attributes">
          <li
            v-if="!isZero(attribute.value)"
            :class="$style.attribute"
            :key="`${attribute.key}-attr-${index}`"
          >
            <span :class="$style.prefix" v-html="attribute.header"></span>
            <span :class="$style.valueBlock">
              <span :class="$style.value" v-html="formatValue(attribute.value)"></span>
            </span>
            <span
              :class="[$style.footer, attribute.header === '-' ? $style.negative : '']"
              v-html="attribute.footer"
            ></span>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'item-enchantment',
  props: {
    itemDetails: {
      type: Object,
      required: true
    },
    level: {
      type: Number,
      required: true
    }
  },
  methods: {
    isZero(value) {
      if (Array.isArray(value)) {
        return parseInt(value[this.level - 1]) === 0
      }
      return parseInt(value) === 0
    },
    formatValue(value) {
      if (Array.isArray(value)) {
        return `<span>${value[this.level - 1]}</span>`
      }
      return `<span>${value}</span>`
    }
  }
}
</script>

<style lang="scss" module>
.container {
  padding: 0.4rem 0.6rem;
}

.header {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  .dash {
    flex: 1;
    width: auto;
    height: 0.05rem;
    background: $color_dark_bluishgrey;
  }

  .title {
    color: $color_dark_bluishgrey;
    font-size: 0.6rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.item {
  display: flex;
  align-items: center;
  gap: 0.4rem;

  .border {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: inset 0 0 0.4rem black;

    .image {
      position: absolute;
      height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .name {
    color: $color_white;
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.stats {
  padding: 0.4rem 0.2rem 0 0.4rem;

  .attributes {
    list-style: none;

    .attribute {
      margin-bottom: 0.1rem;
      font-size: 0.8rem;
      letter-spacing: 0 !important;
    }

    .prefix {
      color: rgba($color_white, 0.8);
      margin-right: 0.1rem;
    }

    .valueBlock {
      margin-right: 0.2rem;
    }

    .value {
      span {
        color: $color_white;
        font-weight: bold;
      }
    }
  }
}

.footer {
  color: rgba($color_white, 0.8);

  &.negative {
    color: $color_red;
  }
}
</style>
