<template>
  <div v-if="hero" id="hud-innate-facet" :class="$style.container">
    <innate-facet-tooltip :hero="hero.name" :selectedFacet="hero.facet" position="top" />
  </div>
</template>

<script>
import InnateFacetTooltip from '../tooltips/innate-facet-tooltip.vue'

export default {
  name: 'hid-innate-facet',
  components: { InnateFacetTooltip },
  computed: {
    hero() {
      return this.$store.getters.selectedPlayer.hero
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: absolute;
  top: 0.35vw;
  left: 11.9vw;
  height: 3.4vw;
  width: 1.8vw;
  border-radius: 0.4vw;
  cursor: pointer;
}
</style>
