export default function install(Vue) {
  Vue.$staticData = Vue.prototype.$staticData = {
    abilities: null,
    aghanims: null,
    heroAbilities: null,
    heroes: null,
    items: null,
    levels: null,
    talents: null,
    shopkeeper: null,
    facets: null
  }

  Vue.mixin({
    data() {
      return {
        sdRetryCount: [],
        sdList: [
          {
            endpoint: '{lang}/abilities',
            key: 'abilities'
          },
          {
            endpoint: '{lang}/aghanims',
            key: 'aghanims'
          },
          {
            endpoint: '{lang}/items',
            key: 'items'
          },
          {
            endpoint: '{lang}/hero-facets',
            key: 'facets'
          },
          {
            endpoint: '{lang}/talents',
            key: 'talents'
          },
          {
            endpoint: 'hero-abilities',
            key: 'heroAbilities'
          },
          {
            endpoint: 'heroes',
            key: 'heroes'
          },
          {
            endpoint: 'levels',
            key: 'levels'
          },
          {
            endpoint: 'shopkeeper',
            key: 'shopkeeper'
          }
        ],
        dataLanguage: 'en',
        staticDataReady: false
      }
    },
    methods: {
      initializeStaticData() {
        // Reset
        this.staticDataReady = false

        // Fetch Static Data
        this.sbStartFetch()

        // Check if data is ready
        const checker = setInterval(() => {
          if (
            this.$staticData.abilities !== null &&
            this.$staticData.aghanims !== null &&
            this.$staticData.heroAbilities !== null &&
            this.$staticData.heroes !== null &&
            this.$staticData.items !== null &&
            this.$staticData.levels !== null &&
            this.$staticData.talents !== null &&
            this.$staticData.shopkeeper !== null &&
            this.$staticData.facets !== null
          ) {
            console.log('Data complete!')
            this.staticDataReady = true
            clearInterval(checker)
          } else {
            console.log('Data incomplete...')
          }
        }, 300)
      },
      sbStartFetch() {
        for (let x = 0; x < this.sdList.length; x++) {
          console.log(`Fetching: ${this.sdList[x].key}`)
          this.sdRetryCount[this.sdList[x].key] = 0
          Promise.resolve(this.sbFetchStaticData(this.sdList[x]))
        }
      },
      sbFetchStaticData(data) {
        this.sdRetryCount[data.key]++
        this.axios
          .get(
            `${process.env.VUE_APP_STATS_URL}/static/${data.endpoint.replace(
              '{lang}',
              this.dataLanguage
            )}.json`
          )
          .then((response) => {
            this.$staticData[data.key] = response.data
            console.log(`${data.key} data saved!`)
          })
          .catch((_) => {
            console.log(`Failed to fetch: ${data.key}`)
            setTimeout(() => {
              if (this.sdRetryCount[data.key] <= 3) {
                console.log(`Retrying fetch ${data.key} (${this.sdRetryCount[data.key]})`)
                return Promise.resolve(this.sbFetchStaticData(data))
              } else {
                console.log('Failed to fetch data... Giving up :(')
              }
            }, 3000)
          })
      },
      getAbilityDetails(ability, field) {
        if (field) {
          return this.$staticData.abilities[ability][field]
        }
        return this.$staticData.abilities[ability]
      },
      getAghsUpgrades(hero, type) {
        return this.$staticData.aghanims[hero][type]
      },
      getHeroAbilities(hero, type) {
        if (type) {
          return this.$staticData.heroAbilities[hero][type]
        }
        return this.$staticData.heroAbilities[hero]
      },
      getHeroDetails(heroName, property) {
        try {
          if (property) {
            return this.$staticData.heroes[heroName.replace('npc_dota_hero_', '')][property]
          }
          return this.$staticData.heroes[heroName.replace('npc_dota_hero_', '')]
        } catch (_) {
          console.log(`Error fetching ${heroName.replace('npc_dota_hero_', '')} ${property}`)
        }
      },
      getItemDetails(item, field) {
        try {
          if (field) {
            if (item !== 'empty') {
              return this.$staticData.items[item.replace('item_', '')][field]
            }
          }
          return this.$staticData.items[item.replace('item_', '')]
        } catch (_) {
          console.log(`Error fetching ${item} ${field}`)
        }
      },
      getXpToLevel(level) {
        let xp = 0

        for (let x = 0; x < level; x++) {
          xp += this.$staticData.levels[x]
        }

        return xp
      },
      getTalents(hero) {
        return this.$staticData.talents[hero]
      },
      getShopkeeperItems(tab) {
        return this.$staticData.shopkeeper[tab]
      },
      getHeroFacets(hero) {
        return this.$staticData.facets[hero].filter((facet) => !facet.isDeprecated)
      },
      getFacetData(hero, id) {
        const facets = this.$staticData.facets[hero]
        return facets.find((facet) => facet.id === id)
      },
      setLanguage(language) {
        this.dataLanguage = language
      }
    }
  })
}
