<template>
  <div :class="$style.container">
    <div :class="$style.header">Crafting Status</div>
    <div :class="$style.tiers">
      <div :class="$style.tier_label">
        <div :class="[$style.tier, $style.tier_1]">Tier 1</div>
        <div :class="$style.time">5:00+</div>
      </div>
      <div :class="$style.tier_label">
        <div :class="[$style.tier, $style.tier_2]">Tier 2</div>
        <div :class="$style.time">15:00+</div>
      </div>
      <div :class="$style.tier_label">
        <div :class="[$style.tier, $style.tier_3]">Tier 3</div>
        <div :class="$style.time">25:00+</div>
      </div>
      <div :class="$style.tier_label">
        <div :class="[$style.tier, $style.tier_4]">Tier 4</div>
        <div :class="$style.time">35:00+</div>
      </div>
      <div :class="$style.tier_label">
        <div :class="[$style.tier, $style.tier_5]">Tier 5</div>
        <div :class="$style.time">60:00+</div>
      </div>
    </div>
    <div
      v-for="team in ['radiant', 'dire']"
      :class="[$style.team, $style[team]]"
      :key="`team-${team}`"
    >
      <div :class="$style.title">The {{ team }}</div>
      <div :class="$style.rows">
        <div
          v-for="(player, index) in getTeamPlayers(team)"
          :class="$style.row"
          :key="`${team}-player-${index}`"
        >
          <img :src="getPlayerHeroIcon(player.hero)" :class="$style.hero" />
          <div
            v-for="tier in 5"
            :class="[
              $style.tier_notch,
              $style[`tier_${tier}`],
              tier <= activeTier ? $style.active : ''
            ]"
            :key="`player-${index}-tier-${tier}`"
          >
            <div :class="$style.bar">
              <div
                :class="$style.progress"
                :style="`min-width: ${getBarProgress(
                  tier,
                  player.requiredMadstone,
                  player.currentMadstone,
                  player[`tier${tier - 1}`].crafted,
                  player[`tier${tier - 1}`].crafting
                )};`"
              ></div>
            </div>
            <div
              :class="[$style.notch, player[`tier${tier - 1}`].crafting ? $style.full : '']"
            ></div>
            <div v-if="player[`tier${tier - 1}`].crafted" :class="$style.item">
              <img :class="$style.image" :src="getItemImage(player[`tier${tier - 1}`].artifact)" />
            </div>
            <div :class="$style.tooltip">
              <item-tooltip
                :item="player[`tier${tier - 1}`].artifact"
                :enchantment="{
                  name: player[`tier${tier - 1}`].enchantment,
                  level: player[`tier${tier - 1}`].enchantmentLevel
                }"
                position="left"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTooltip from '../tooltips/item-tooltip.vue'

export default {
  name: 'neutral-teams',
  components: { ItemTooltip },
  props: {
    categories: {
      type: Array,
      required: true
    }
  },
  computed: {
    radiant() {
      return this.$store.getters.radiantNeutrals
    },
    dire() {
      return this.$store.getters.direNeutrals
    },
    activeTier() {
      const clockTime = this.$store.getters.clockTime

      if (clockTime >= 3600) {
        return 5
      } else if (clockTime >= 2100) {
        return 4
      } else if (clockTime >= 1500) {
        return 3
      } else if (clockTime >= 900) {
        return 2
      }

      return 1
    }
  },
  methods: {
    getTeamPlayers(team) {
      if (team === 'radiant') {
        return this.radiant
      }
      return this.dire
    },
    getPlayerHeroIcon(hero) {
      return this.getHeroDetails(hero, 'icon')
    },
    getItemImage(item) {
      return this.getItemDetails(item, 'image')
    },
    getBarProgress(tier, required, current, crafted, crafting) {
      if (tier < this.activeTier || crafted || crafting) {
        return '100%'
      } else if (this.activeTier === tier) {
        const percentage = (current / required) * 100
        return `${percentage}%`
      } else {
        return '0%'
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5vw;
  padding: 0.1vw;
}

.header {
  width: 95%;
  padding: 0.4vw 0;
  color: $color_white;
  font-size: 1vw;
  font-weight: bold;
  font-family: 'Reaver';
  text-align: center;
  text-transform: uppercase;
  border-bottom: 0.1vw solid rgba($color_white, 0.3);
}

.tiers {
  width: 100%;
  padding-right: 0.4vw;
  display: flex;
  justify-content: flex-end;
  gap: 1vw;

  .tier_label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .tier {
      font-size: 0.8vw;
      font-weight: bold;
      text-transform: uppercase;

      &.tier_1 {
        color: $color_tier_1;
      }

      &.tier_2 {
        color: $color_tier_2;
      }

      &.tier_3 {
        color: $color_tier_3;
      }

      &.tier_4 {
        color: $color_tier_4;
      }

      &.tier_5 {
        color: $color_tier_5;
      }
    }

    .time {
      font-size: 0.7vw;
      color: rgba($color_white, 0.8);
    }
  }
}

.team {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 95%;

  &.radiant {
    border-top: 0.1vw solid rgba($color_green, 0.3);
    background: radial-gradient(circle at 50% -40%, rgba($color_green, 0.25) 0%, transparent 60%);
  }

  &.dire {
    border-top: 0.1vw solid rgba($color_red, 0.3);
    background: radial-gradient(circle at 50% -40%, rgba($color_red, 0.25) 0%, transparent 60%);
  }

  .title {
    padding: 0.6vw 0;
    color: $color_white;
    font-weight: bold;
    font-size: 0.8vw;
    text-align: center;
    text-transform: uppercase;
  }

  .rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.45vw;
    width: 100%;

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 1.4vw;

      .hero {
        width: 1.5vw;
        height: 1.5vw;
        margin-right: 0.5vw;
        filter: drop-shadow(0 0 0.2vw $color_black);
      }

      .tier_notch {
        position: relative;

        .bar {
          position: relative;
          height: 0.4vw;
          width: 3.4vw;
          background: $color_black;
          border: 0.1vw solid rgba($color_white, 0.2);

          .progress {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            min-width: 0%;
            background: $color_tier_1;
            transition: min-width ease-out 0.3s;
          }
        }

        .notch {
          position: absolute;
          right: -0.4vw;
          top: 50%;
          transform: translateY(-50%);
          width: 0.6vw;
          height: 0.6vw;
          border-radius: 50%;
          border: 0.15vw solid rgba($color_tier_1, 0.8);
          box-shadow: 0 0 0.1vw $color_black;
          z-index: 10;

          &:not(.full) {
            background: $color_black !important;
          }
        }

        .item {
          position: absolute;
          right: -0.8vw;
          top: 50%;
          transform: translateY(-50%);
          width: 1.6vw;
          height: 1.6vw;
          border-radius: 50%;
          overflow: hidden;
          background: $color_black;
          border: 0.15vw solid rgba($color_tier_1, 0.8);
          box-shadow: 0 0 0.1vw $color_black;
          z-index: 20;

          .image {
            position: absolute;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .tooltip {
          position: absolute;
          right: -0.8vw;
          top: 50%;
          transform: translateY(-50%);
          width: 1.6vw;
          height: 1.6vw;
          border-radius: 50%;
          z-index: 30;
        }

        &:nth-child(2) {
          .bar {
            width: 2.3vw;
          }
        }

        &.tier_1 {
          .notch {
            background: $color_tier_1;
          }
        }

        &.tier_2 {
          .bar .progress {
            background: $color_tier_2;
          }

          .notch {
            background: $color_tier_2;
            border-color: rgba($color_tier_2, 0.8);
          }
        }

        &.tier_3 {
          .bar .progress {
            background: $color_tier_3;
          }

          .notch {
            background: $color_tier_3;
            border-color: rgba($color_tier_3, 0.8);
          }
        }

        &.tier_4 {
          .bar .progress {
            background: $color_tier_4;
          }
          .notch {
            background: $color_tier_4;
            border-color: rgba($color_tier_4, 0.8);
          }
        }

        &.tier_5 {
          .notch {
            background: $color_tier_5;
            border-color: rgba($color_tier_5, 0.8);
          }

          .bar .progress {
            background: $color_tier_5;
          }
        }

        &.active {
          &.tier_1 {
            .bar {
              border: 0.15vw solid $color_black;
              background: rgba($color_tier_1, 0.3);
            }
          }

          &.tier_2 {
            .bar {
              border: 0.15vw solid $color_black;
              background: rgba($color_tier_2, 0.3);
            }
          }

          &.tier_3 {
            .bar {
              border: 0.15vw solid $color_black;
              background: rgba($color_tier_3, 0.3);
            }
          }

          &.tier_4 {
            .bar {
              border: 0.15vw solid $color_black;
              background: rgba($color_tier_4, 0.3);
            }
          }

          &.tier_5 {
            .bar {
              border: 0.15vw solid $color_black;
              background: rgba($color_tier_5, 0.3);
            }
          }
        }
      }
    }
  }
}
</style>
